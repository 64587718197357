import {Router} from '@angular/router';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '@ff/auth';
@Component({
  selector: 'app-auth-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) {

    this._authService.logout().then(
      (done: boolean) => {
        this._router.navigate(['/']);
      }
    );
  }

  ngOnInit() {
  }

}
