import { Component, Host, Optional, Inject, OnDestroy } from '@angular/core';
import { Route, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { NAVIGATION_CONFIG } from './navigation.token';
import { Subject, Observable } from 'rxjs';
import { UserService } from '@ff/user';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {
  // tslint:disable-next-line:variable-name
  private _routes: Route[] = [];
  // tslint:disable-next-line:variable-name
  private _alive: Subject<any>;

  get isConnected() {
    return this.userService._user.value;
  }

  constructor(
    // tslint:disable-next-line:variable-name
    @Host() @Optional() private _sidenav: MatSidenav,
    // tslint:disable-next-line:variable-name
    @Inject(NAVIGATION_CONFIG) @Optional() private _config: Route[] | Observable<Route[]> | any,
    // tslint:disable-next-line:variable-name
    private _router: Router,
    private userService: UserService
  ) {
    this._alive = new Subject();
  }

  public getRoutes(): Route[] {
    return this._router.config;
  }

  public close(): void {
    if (this._sidenav) {
      this._sidenav.close();
    }
  }

  ngOnDestroy() {
    this._alive.next();
    this._alive.complete();
  }
}
