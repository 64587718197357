<div class="table-container mb-5">
    <table mat-table [dataSource]="dataSourceGroupBy">
        <ng-container matColumnDef="ref_emmy">
            <th mat-header-cell *matHeaderCellDef class="text-center">Ref. Emmy</th>
            <td mat-cell *matCellDef="let value" class="text-center">
                <mat-icon style="cursor: pointer;color:#ee0000" (click)="refEmmyDetails(value.file_id)">remove_red_eye</mat-icon>
                <!-- <button mat-raised-button  class="mr-3" >
                    Voir les réf. Emmy
                </button>     -->
            </td>
        </ng-container>

        <ng-container matColumnDef="type_cee">
            <th mat-header-cell *matHeaderCellDef class="text-center">Type CEE</th>
            <td mat-cell *matCellDef="let value" class="text-center">
                {{  value.type_cee ? value.type_cee : '-' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="last_name">
            <th mat-header-cell *matHeaderCellDef class="text-center">Nom</th>
            <td mat-cell *matCellDef="let value" class="text-center">
                {{ value.last_name ? value.last_name : '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="first_name">
            <th mat-header-cell *matHeaderCellDef class="text-center">Prenom</th>
            <td mat-cell *matCellDef="let value" class="text-center">
                {{ value.first_name ? value.first_name : '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="date_import">
            <th mat-header-cell *matHeaderCellDef class="text-center">Date import</th>
            <td mat-cell *matCellDef="let value" class="text-center">
                {{ value.date_import ? (value.date_import | date: "dd/MM/yyyy HH:mm:ss ") : '-' }}</td>
        </ng-container>

        <ng-container matColumnDef="btn_suppression">
            <th mat-header-cell *matHeaderCellDef class="text-center"></th>
            <td mat-cell *matCellDef="let value" class="text-center">
                <mat-icon class="oem-icon" style="cursor: pointer;color:#ee0000" (click)="remove(value.file_id)">delete</mat-icon>
                <!-- <button mat-raised-button color="primary" >
                    Supprimer
                </button> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"  [ngClass]="{ 'highlight': true, 'red': row.numberOfDuplicates > 0, 'green': 0 }">
        </tr>
    </table>
</div>