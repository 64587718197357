import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
  ) { }

  showPrompt(text: string, value: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { text, value, type: 'prompt' }
    });

    return dialogRef;
  }

  showConfirm(text: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { text, value: true, type: 'confirm' }
    });

    return dialogRef;
  }

  showAlert(text: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { text, type: 'alert' }
    });

    return dialogRef;
  }

  showErrorMsg(text: string, error: any) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { title: 'Application Error', text, type: 'alert' }
    });

    return dialogRef;
  }
}
