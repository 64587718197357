<div class="container-fluid">
  <div class="row no-gutters">
    <h1 class="col-12 pb-3">Mon compte</h1>
    <div class="d-flex align-items-center w-100 mb-3">
      <small>Les champs marqués d'un astérisque (*) sont obligatoires</small>
      <a class="ml-auto d-lg-flex align-items-center d-none" routerLink="/auth/logout">
        <mat-icon>arrow_right</mat-icon> Me déconnecter
      </a>
    </div>
    <div class="d-flex user-info justify-content-center flex-column col-12 mb-4" [formGroup]="getUserInfoForm()">
      <div class="row">
        <h2 class="col-12">Identité</h2>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Prénom *</mat-label>
          <input type="text" matInput formControlName="first_name" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Nom *</mat-label>
          <input type="text" matInput formControlName="last_name" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Email *</mat-label>
          <input type="email" matInput formControlName="email" />
        </mat-form-field>
        <div class="col-12 d-flex justify-content-end pt-3">
          <button mat-raised-button color="primary" (click)="saveUserInfo()" [disabled]="getUserInfoForm().invalid">
            <mat-icon>person</mat-icon>
            Mettre à jour mon compte
          </button>
        </div>
      </div>
    </div>
    <div class="d-flex user-password justify-content-center flex-column col-12 mb-4"
      [formGroup]="getUserPasswordForm()">
      <div class="row">
        <h2 class="col-12 d-flex align-items-center">Mot de passe
          <mat-icon class="ml-3 hint" matTooltipClass="hint-text"
            matTooltip="Votre mot de passe doit faire au moins 8 caractères dont : 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial">
            info
          </mat-icon>
        </h2>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Mot de passe actuel *</mat-label>
          <input type="password" matInput formControlName="oldPassword" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Nouveau mot de passe *</mat-label>
          <input type="password" matInput placeholder="New password*" formControlName="newPassword" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>Confirmer le nouveau mot de passe *</mat-label>
          <input type="password" matInput formControlName="newPassword_confirmation" />
        </mat-form-field>
        <div class="col-12 d-flex justify-content-end pt-3">
          <button mat-raised-button color="primary" (click)="saveUserPassword()"
            [disabled]="getUserPasswordForm().invalid">
            <mat-icon>lock</mat-icon>
            Mettre à jour mon mot de passe
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
