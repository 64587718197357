import { NgModule, SkipSelf, Optional } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { SharedModule } from '../shared/shared.module';
import { AppComponent } from './components/app/app.component';
import { API_CONFIG } from '@ff/api';
import { environment } from '../../../environments/environment';
import { RouterModule } from '@angular/router';
import { routes } from './core.routes';
import { ATInternetModule } from '@ff/at-internet';
import { UserModule, USER_CONFIG } from '@ff/user';
import { AuthModule, AUTH_CONFIG } from '@ff/auth';
import { AuthModule as AppAuthModule } from '../auth/auth.module';
import { UserModule as AppUserModule } from '../user/user.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatCardModule } from '@angular/material/card';
import { DeleteFilesComponent } from './components/delete-files/delete-files.component';
import { DeleteFilesTableComponent } from './components/delete-files/delete-files-table/delete-files-table.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NavigationComponent,
    DeleteFilesComponent,
    DeleteFilesTableComponent
  ],
  providers: [
    {
      provide: API_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: USER_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
    {
      provide: AUTH_CONFIG,
      useValue: {
        host: environment.api.host
      }
    },
  ],
  imports: [

    // FF packages

    UserModule,
    AuthModule,
    ATInternetModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatCardModule,

    // Angular Packages

    RouterModule.forRoot(routes),

    // App packages

    SharedModule,
    AppAuthModule,
    AppUserModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class CoreModule {

  public constructor(@Optional() @SkipSelf() parent: CoreModule) {
    if (parent) {
      throw new Error('CoreModule should not be exported then imported by another module!');
    }

  }

}
