import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { endpoints } from 'src/environments/endpoints';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CeeService {

    constructor(
        private _http: HttpClient,
    ) { }

    import(file, comparisonType): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('comparisonType', comparisonType);

        return this._http.post(environment.api.host + endpoints.importCee, formData);
    }

    getImportStatus(ceeFileId: number): Observable<any> {
        return this._http.get(environment.api.host + endpoints.getImportStatus + '/' + ceeFileId);
    }

    getImportResults(ceeFileId: number): Observable<any> {
        return this._http.get(environment.api.host + endpoints.getImportResults + '/' + ceeFileId);
    }

    getDateLastImport(): Observable<any> {
        return this._http.get(environment.api.host + endpoints.getDateLastImport);
    }
    
    getCeeOverView(): Observable<any> {
        return this._http.get(environment.api.host + endpoints.getCeeOverView);
    }

    export(ceeFileId: number): Observable<any> {
        const params: any = { responseType: 'arraybuffer' };
        return this._http.post(environment.api.host + endpoints.exportCee, { ceeFileId }, params);
    }

    save(ceeFileTypeIds: number[]): Observable<any> {
        return this._http.post(environment.api.host + endpoints.saveCee, { ceeFileTypeIds });
    }

    remove(ceeFileId: number): Observable<any> {
        return this._http.post(environment.api.host + endpoints.removeCee, { ceeFileId });
    }
}
