import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { LoaderService } from '@ff/loader';
import { OAuth2Service } from '@ff/oauth2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetPasswordLinkComponent } from '../reset-password-link/resetPasswordLink.component';
import { ResetPasswordComponent } from '../reset-password/resetPassword.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-auth-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public error: any;

  /**
   * Reset password link modal
   */
  public showResetPasswordLinkModal: boolean;

  /**
   * Reset password modal
   */
  public showResetPasswordModal: boolean;

  public token: string;

  constructor(
    private _authService: AuthService,
    fb: FormBuilder,
    private _router: Router,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _loaderService: LoaderService,
    private _oauth2: OAuth2Service
  ) {
    this.error = {};

    this.loginForm = fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.showResetPasswordLinkModal = false;

    this._activatedRoute.queryParams.subscribe (
      (params: any) => {
        if (params.token) {
          this.token = params.token;
          this.showResetPasswordDialog();
        }
      }
    );

    if (this._userService.getUser().getValue()) {
      this._router.navigate(['/import']);
    }
  }

  public loginWithOAuth2(url: string) {

    this._loaderService.show();
    this._oauth2.login(url);
  }

  public getOAuth2Providers(): BehaviorSubject<any> {
    return this._oauth2.getProviders();
  }

  public redirect(url: string) {
    window.location.href = url;
  }

  public login(form: FormGroup) {

    // Prevent spam submit
    if (form.valid && !this._loaderService.isVisible()) {

      //this._loaderService.show();

      this._authService.login(form.controls.login.value, form.controls.password.value).then(
        (user: any) => {
          this._loaderService.hide();
          this._router.navigate(['/import']);
        }
      ).catch(
        (response) => {
          this._loaderService.hide();
          this._snackBar.open(response.error.message, 'Close', {
            duration: 3000
          });
        }
      );

    }
  }

  public showResetPasswordLinkDialog() {
    this._matDialog.open(ResetPasswordLinkComponent);
  }

  public showResetPasswordDialog() {

    this._matDialog.open(ResetPasswordComponent, {
      data: { token: this.token }
    });
  }

  ngOnInit() {
  }

}
