export const endpoints = {
    login: '/auth/login',
    importCee: '/importCee',
    exportCee: '/exportCee',
    saveCee: '/saveCee',
    getImportStatus: '/getImportStatus',
    getImportResults: '/getImportResults',
    getDateLastImport: '/getDateLastImport',
    getCeeOverView: '/getCeeOverView',
    removeCee: '/removeCee'
};
