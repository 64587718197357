<h2 mat-dialog-title class="text-uppercase">Reset your password</h2>
<form mat-dialog-content [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <p>Please fill out the form below to change your password.</p>
    <p>Your password must contain at least 6 characters, one symbol and one capital letter.</p>
    <mat-form-field class="w-100">
        <input matInput placeholder="email@domain.com" type="email" formControlName="email"/>
        <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>
    <mat-form-field class="w-100">
        <input matInput class="mt-3" prefixIcon="lock" placeholder="Password" type="password" formControlName="password" />
        <mat-icon matSuffix>lock</mat-icon>
    </mat-form-field>
    <mat-form-field class="w-100">
        <input matInput class="mt-3" prefixIcon="lock" placeholder="Confirm your password" type="password" formControlName="password_confirmation"/>
        <mat-icon matSuffix>lock</mat-icon>
    </mat-form-field>
</form>
<div mat-dialog-actions class="d-flex justify-content-start mb-1">
    <button mat-raised-button class="align-self-start py-1" color="primary" [disabled]="!form.valid" (click)="submit()">
        Reset my password
    </button>
</div>
