
  <mat-sidenav-container class="h-100">
      <mat-sidenav #nav mode="push" class="h-100">
        <ff-navigation></ff-navigation>
      </mat-sidenav>
      <mat-sidenav-content class="d-flex flex-column">
          <app-header [nav]="nav"></app-header>
          <main>
            <router-outlet></router-outlet>
          </main>
      </mat-sidenav-content>
  </mat-sidenav-container>
