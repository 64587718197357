<h1>Supprimer les fichiers importés</h1>
<p>
    En cas d'erreur, vous avez la possibilité de supprimer un fichier précedemment importé.
</p>


<mat-card *ngIf="ceeViewData" class="mb-5">
    <div>
        <h3>Fichiers importés :</h3>
        <ng-container>
            <app-delete-files-table [ceeViewData]="ceeViewData"></app-delete-files-table>
        </ng-container>
    </div>
</mat-card>
