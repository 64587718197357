<div class="pt-5">
    <form [formGroup]="form">
        <h3>Nombre de colonnes à comparer</h3>
        <mat-form-field class="mb-5">
            <mat-select formControlName="nbCriteria">
                <mat-option *ngFor="let nbColumn of nbColumnValues" [value]="nbColumn">
                {{ nbColumn }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <h3>Ligne 1</h3>
        <div class="row pb-5">
            <div *ngFor="let criteria of form['controls'].row_1['controls']; let i = index" formArrayName="row_1" class="col-md-2">
                <mat-form-field>
                    <mat-label>Valeur {{i + 1}}</mat-label>
                    <input matInput [formControlName]='i'>
                </mat-form-field>
            </div>
        </div>

        <h3>Ligne 2</h3>
        <div class="row pb-5">
            <div *ngFor="let criteria of form['controls'].row_2['controls']; let i = index" formArrayName="row_2" class="col-md-2">
                <mat-form-field>
                    <mat-label>Valeur {{ i + 1 }}</mat-label>
                    <input matInput [formControlName]='i'>
                </mat-form-field>
            </div>
        </div>
        <div class="pb-5" *ngIf="rates && rates.length > 0">
            <h3>Résultats</h3>
            <table mat-table [dataSource]="dataSource" class="col-12">
                <ng-container matColumnDef="word_1" >
                    <th mat-header-cell *matHeaderCellDef>Mot ligne 1</th>
                    <td mat-cell *matCellDef="let value">{{ value.word_1 }}</td>
                </ng-container>
                <ng-container matColumnDef="word_2" >
                    <th mat-header-cell *matHeaderCellDef>Mot ligne 2</th>
                    <td mat-cell *matCellDef="let value">{{ value.word_2 }}</td>
                </ng-container>
                <ng-container *ngFor="let column of displayedColumns; let i = index">
                    <ng-container *ngIf="i > 1" [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef>{{ column | titlecase }}</th>
                        <td mat-cell *matCellDef="let value">{{ value.rates[column] }}</td>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <button mat-raised-button color="primary" (click)="submit()">
            Valider
        </button>
    </form>
</div>

<div>
    <input
    class="file-input col-12 mt-2"
    type="file"
    (change)="uploads($event)"
  />
  <button
    class="align-self-center mt-2"
    mat-raised-button
    color="primary"
    type="button"
    (click)="importFile()"
  >
    <mat-icon>cloud_upload</mat-icon>
    Import file
  </button>
</div>

