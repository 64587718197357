import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CoreModule } from '@ff/core';
import { LoaderModule } from '@ff/loader';
import { NavigationModule } from '@ff/navigation';
import { ThemeModule } from '@ff/theme';
import { DialogComponent } from './components/dialog/dialog.component';



@NgModule({
    declarations: [
        DialogComponent
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', floatLabel: 'always' } }
    ],
    imports: [

        // Angular

        ReactiveFormsModule,

        // FF Packages

        LoaderModule,
        CoreModule,
        CommonModule,
        NavigationModule,
        ThemeModule,

        // Angular Material

        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,

    ],
    exports: [

        ReactiveFormsModule,
        CommonModule,

        // FF Packages

        LoaderModule,
        CoreModule,
        NavigationModule,
        ThemeModule,

        // Angular Material

        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule
    ]
})
export class SharedModule {

    public constructor() {

    }

}
