import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '@ff/user';
import { Observable } from 'rxjs';
import { __values } from 'tslib';


@Injectable({
  providedIn: 'root'
})
export class DeleteFilesGuard implements CanActivate {
  private userService;
  constructor( userService: UserService) {
    this.userService = userService;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let last_name = this.userService.getUser().getValue().last_name;
    let email = this.userService.getUser().getValue().email;
    let usersAllowed = ["porotto", "allirot"];
    for (let user of usersAllowed) {
      if (email.toLowerCase().includes(user) && last_name.toLowerCase().includes(user)) {
        return true;
      }
    }  
    return false;
  }
}
