<div class="dialog-wrapper">
    <div mat-dialog-content>
        <span class="text">{{ data.text }}</span>
        <mat-form-field appearance="outline" *ngIf="data.type == 'prompt'">
            <input type="text" matInput>
        </mat-form-field>
    </div>
    <div class="buttons-bar">
        <ng-container *ngIf="data.type == 'prompt'">
            <button mat-raised-button color="primary" (click)="onClick()">Valider</button>
            <button cdk-focus-start mat-raised-button (click)="onNoClick()">Annuler</button>
        </ng-container>
        <ng-container *ngIf="data.type == 'confirm'">
            <button mat-raised-button color="primary" (click)="onClick()">Oui</button>
            <button cdk-focus-start mat-raised-button (click)="onNoClick()">Non</button>
        </ng-container>
        <ng-container *ngIf="data.type == 'alert'">
            <button cdk-focus-start mat-raised-button color="primary" (click)="onClick()">OK</button>
        </ng-container>
    </div>
</div>
