import { Routes } from '@angular/router';
import { LoggedInGuard } from '@ff/core';
import { DeleteFilesGuard } from 'src/app/delete-files.guard';
import { DeleteFilesComponent } from './components/delete-files/delete-files.component';
import { HomeComponent } from './components/home/home.component';

export const routes: Routes = [
    /* { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard], data: { title: 'Home' } },*/
    {
        path: 'import',
        loadChildren: () => import('../import/import.module').then((m) => m.ImportModule),
        canActivate: [LoggedInGuard],
        data: { title: 'Import / Recherche' }
    },
    { path: 'delete-files', component: DeleteFilesComponent, canActivate: [DeleteFilesGuard], data: { title: 'Suppression fichiers' } },
];
