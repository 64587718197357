import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@ff/loader';
import { CeeService } from 'src/app/modules/import/services/cee.service';

@Component({
   selector: 'app-delete-files',
    templateUrl: './delete-files.component.html',
    styleUrls: ['./delete-files.component.scss']
})
export class DeleteFilesComponent implements OnInit {

    ceeViewData: any[];
    
    constructor(
        private _ceeService: CeeService,
        private _loaderService: LoaderService
    ) { 
    }

    ngOnInit(): void {
        this._loaderService.show();
        this.getCeeOverView();
    }

    getCeeOverView(): void {
        this._ceeService.getCeeOverView().subscribe(
            response => {
                this.ceeViewData = response.data.ceeFiles;
            }, error => {
                this.ceeViewData = null;
            }
        );
    }
}
