import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';

const nbColumnMax: number = 5;
const defaultNbColumn: number = 3;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    form: FormGroup;
    formUpload: FormControl = new FormControl(null);
    rates: number[];
    defaultNbColumn: number;
    nbColumnValues: number[];
    nbCriteria: number;

    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = [
        'word_1', 'word_2', 'jaroWinkler', 'levenshtein', 'soundex', 
        'metaphone', 'similar_text', 'inverted_similar_text'
    ];

    
    constructor(
        private _fb: FormBuilder,
        private _http: HttpClient
    ) { 
        this.nbCriteria = defaultNbColumn;
        this.nbColumnValues = [];

        this.setNbColumnValues();
    }

    ngOnInit(): void {
        this.initForm();
        this.initFormEvents();

        this.dataSource = new MatTableDataSource();
    }

    initForm(): void {
        this.form = this._fb.group({
            nbCriteria: [defaultNbColumn],
            row_1: this._fb.array([]),
            row_2: this._fb.array([])
        });

        this.updateNbRows();
    }

    initFormEvents(): void {
        this.form.controls.nbCriteria.valueChanges.subscribe(value => {
            this.nbCriteria = value;
            this.updateNbRows();
        });
    }

    submit(): void {
        this.testService().subscribe((response: any) => {
            this.rates = response.data;
            this.updateDatasource();
        });
    }

    updateNbRows(): void {
        (<FormArray>this.form.get('row_1')).clear();
        (<FormArray>this.form.get('row_2')).clear();

        for (let i = 0; i < this.nbCriteria; i++) {
            (<FormArray>this.form.get('row_1')).push(this._fb.control([]));
            (<FormArray>this.form.get('row_2')).push(this._fb.control([]));
        }
    }

    setNbColumnValues(): void {
        for (let i = 1; i <= nbColumnMax; i++) {
            this.nbColumnValues.push(i);
        }
    }

    updateDatasource() {
        this.dataSource.data = this.rates;
    }

    testService() {
        return this._http.post(environment.api.host + '/compare', this.form.getRawValue());
    }

    uploads(event: any) {
      this.formUpload.patchValue(event.target.files[0]);
    }

    importFile() {
        const formData = new FormData();
        formData.append('file', this.formUpload.value);
        return this._http.post(environment.api.host + '/importCee', formData).subscribe(_ => console.log('ok'));
    }
}
