import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { LoaderService } from '@ff/loader';
import { CeeService } from 'src/app/modules/import/services/cee.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-delete-files-table',
    templateUrl: './delete-files-table.component.html',
    styleUrls: ['./delete-files-table.component.scss']
})
export class DeleteFilesTableComponent implements OnInit {
    @Input() ceeViewData: any[];
    ceeViewDataGroupBy: any[];

    displayedColumns: string[] = ['ref_emmy', 'type_cee', 'last_name', 'first_name', 'date_import', 'btn_suppression'];
    nbInitialColumns: number;
    dataSourceAll: MatTableDataSource<any>;
    dataSourceGroupBy: MatTableDataSource<any>;
    form: FormGroup;
    showAllRows: boolean;

    constructor(
        private _fb: FormBuilder,
        private _http: HttpClient,
        private _snackBar: MatSnackBar,
        private _dialogService: DialogService,
        private _loaderService: LoaderService,
        private _ceeService: CeeService,
    ) {
        this.nbInitialColumns = this.displayedColumns.length;
    }

    ngOnInit() {
        this.initDataSource();
        this._loaderService.hide();
    }

    groupBy(list, keyGetter) {
        const map = new Array();
        var lastKey = 0;
        list.forEach((item) => {

            const key = keyGetter(item);
            if (key == lastKey) {
                return;
            }
            map.push(item);

            lastKey = key;
        });
        return map;
    }

    initDataSource(): void {
        this.ceeViewDataGroupBy = this.groupBy(this.ceeViewData, data => data.file_id);
        this.dataSourceGroupBy = new MatTableDataSource(this.ceeViewDataGroupBy);
        this.dataSourceAll = new MatTableDataSource(this.ceeViewData);
    }

    remove(ceeFileId: number): void {
        const dialogRef = this._dialogService.showConfirm(
            'Êtes-vous sûr de vouloir supprimer le fichier ?'
        );

        dialogRef.afterClosed().subscribe((confirm: boolean) => {
            if (confirm === true) {
                this._loaderService.show();
                this._ceeService.remove(ceeFileId).subscribe(
                    res => {
                        this._snackBar.open('Le fichier a été supprimé avec succès.');
                        setTimeout(() => window.location.reload(), 1000);
                        
                    }, error => {
                        this._snackBar.open('Une erreur est survenue lors de la suppression du fichier.');
                        setTimeout(() => window.location.reload(), 1000);
                    }
                );
            }
        });
    }

    refEmmyDetails(ceeFileId: number): void {
        const allData = this.ceeViewData;
        var refEmmyList = '';
        allData.map(function (value) {
            if (value.file_id === ceeFileId && value.ref_emmy !== 'null') {
                refEmmyList += '\n' + value.ref_emmy;
                return value.ref_emmy;
            }
        });
        const dialogRef = this._dialogService.showAlert(
            refEmmyList
        );
    }
}
